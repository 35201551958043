import * as React from "react"
import { Link } from "gatsby"

import * as styles from "./ContentBox.module.css"

type ContentBoxProps = {
  content?: [{ url: string; title: string }]
}

const ContentBox: React.FC<ContentBoxProps> = ({ content }) => {
  const [showContent, setShowContent] = React.useState<boolean>(false)

  const contentHandler = () => {
    setShowContent(prevState => !prevState)
  }

  if (!content) return <></>

  return (
    <section className={styles.content_box}>
      <header className={styles.content_box__header} onClick={contentHandler}>
        {!showContent ? "Mostrar" : "Ocultar"}
      </header>
      {showContent && (
        <main className={styles.content_box__content}>
          {content.map(({ url, title }) => (
            <Link key={title} to={url}>
              {title}
            </Link>
          ))}
        </main>
      )}
    </section>
  )
}

export default ContentBox
