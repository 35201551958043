import * as React from "react"
import { graphql, Link, PageProps } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"

// Components
import Layout from "components/Layout"
import Main from "components/Layout/Main"
import DefaultSidebar from "components/Sections/Sidebar/DefaultSidebar"
import PageHeader from "components/Sections/Page/PageHeader"
import ContentBox from "components/Sections/Page/ContentBox"
// Model
import { IPage } from "models/Page"
import { IOptionalMetaProps } from "models/Seo"

type DataProps = {
  page: IPage
}

const BlogPostTemplate: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { title, subtitle, thumbnail, description } = data.page.frontmatter
  const { tableOfContents, body } = data.page
  const postThumbnail = thumbnail ? getImage(thumbnail.childImageSharp.gatsbyImageData) : undefined

  const seo: IOptionalMetaProps = {
    title,
    image: thumbnail?.url,
    description: description || `Página de ${title}.`,
  }

  return (
    <Layout seo={seo}>
      <Main sidebar>
        <PageHeader title={title} thumbnail={postThumbnail} />
        {subtitle && <h3 className="title">{subtitle}</h3>}
        <article className="blog-post page" itemScope itemType="http://schema.org/Article">
          <ContentBox content={tableOfContents.items} />
          <MDXRenderer>{body}</MDXRenderer>
          <hr />
        </article>
      </Main>
      <DefaultSidebar />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query PageQuery($slug: String!) {
    page: mdx(fields: { slug: { eq: $slug }, collection: { eq: "pages" } }) {
      id
      body
      timeToRead
      tableOfContents
      frontmatter {
        title
        subtitle
        description
        thumbnail {
          url: publicURL
          childImageSharp {
            gatsbyImageData(width: 920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        tags
      }
    }
  }
`
