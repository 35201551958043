import * as React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

import * as styles from "./PageHeader.module.css"

type PageHeaderProps = {
  title: string
  thumbnail?: IGatsbyImageData
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, thumbnail }) => {
  return (
    <header className={styles.page_header}>
      {thumbnail && <GatsbyImage className={styles.page_header__thumbnail} image={thumbnail} alt={title} />}
      <h2 className={styles.page_header__title}>{title}</h2>
    </header>
  )
}

export default PageHeader
